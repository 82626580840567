import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components/macro';

type Props = {
  to: string;
  className?: string;
  onClick?: () => void;
};

const Link = styled(GatsbyLink)<Props>`
  text-decoration: none;
`;

export default Link;
