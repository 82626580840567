import React, { useState } from 'react';
import { Box, Text, Flex, Button } from 'rebass/styled-components';
import styled from 'styled-components/macro';
import { useSiteQuery } from '../queries/useSiteQuery';
import { NAV, SNS, NAV_FOOTER } from '../utils/constants';
import Logo from '../assets/icons/black.svg';
import Logo_white from '../assets/icons/white.svg';
import Link from './Link';
import ExternalLink from './ExternalLink';

type Props = {
  headroomState: boolean;
  headroomMobileState: boolean;
};

const headerSNS = SNS.filter(({ name }) => /instagram|facebook/.test(name));

const Header = ({ headroomState, headroomMobileState }: Props) => {
  const [isMobileMenuOn, setIsMobileMenuOn] = useState(false);
  const { title } = useSiteQuery();
  const color = ['dark', null, headroomState ? 'white' : null];

  // TODO: 參照設計稿，或許需要修改
  var window_width = 1440;
  if (typeof window !== 'undefined') {
    window_width = window.innerWidth
  }
  const nav_items = (window_width <= 414) ? NAV_FOOTER : NAV;

  return (
    <Box
      width="100%"
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 'coverMenu',
      }}
    >
      <Box
        variant="mobileHeader"
        sx={{
          display: ['flex', null, 'none'],
          alignItems: 'center',
          justifyContent: 'space-between',
          visibility: headroomMobileState ? 'hidden' : null,
          opacity: headroomMobileState ? '0' : null,
          transition: 'opacity .2s ease, visibility .2s',
          transitionDelay: `0s, ${headroomMobileState ? '.2s' : '0s'}`,
        }}
      >
        <Link to="/">
          <Box color="dark" width={['77px', '107px']} height={['15px', '22px']}>
            <Logo title={title} />
          </Box>
        </Link>
        <Button
          className={isMobileMenuOn ? 'active' : ''}
          color="background.purple"
          bg="transparent"
          width="coverMenuToggle"
          height="coverMenuToggle"
          aria-label="menu toggle"
          p={0}
          sx={{
            zIndex: 'coverMenuToggle',
            position: 'relative',
            outline: 0,
            cursor: 'pointer',
          }}
          onClick={() => setIsMobileMenuOn(!isMobileMenuOn)}
        >
          <Bar />
          <Bar my="7px" />
          <Bar />
        </Button>
      </Box>
      <Flex
        justifyContent="center"
        alignItems="center"
        opacity={[isMobileMenuOn ? 1 : 0, null, 1]}
        bg={['background.light', null, headroomState ? 'transparent' : null]}
        width="100%"
        height={['100%', null, 56]}
        p={[null, null, '0 40px', 0]}
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          visibility: [isMobileMenuOn ? 'visible' : 'hidden', null, 'visible'],
          transition: [
            'opacity .3s ease-in-out, visibility .3s',
            null,
            'background .2s ease',
          ],
          transitionDelay: [`0s, ${isMobileMenuOn ? '0s' : '.3s'}`, null, '0s'],
        }}
      >
        <Box
          width="100%"
          maxWidth="containerWide"
          sx={{
            position: [null, null, 'relative'],
          }}
        >
          <Flex
            flexDirection={['column', null, 'row']}
            // justifyContent={['center', null, 'space-between']}
            justifyContent='center'
            alignItems="center"
            width={['100%', null, 'container']}
            height={[null, null, 104]}
            mx="auto"
          >
            {nav_items.map(({ page, name }, index) => (
              <React.Fragment key={page}>
                {index === 0 && (
                  <Box sx={{ order: [-1, null, 0] }}>
                    <Link to="/">
                      <LinkText color={color} display={[null, null, 'none']}>
                        首頁
                      </LinkText>
                      <Box
                        display={['none', null, 'block']}
                        width="107px"
                        height="22px"
                        color={color}
                        sx={{
                          position: 'relative',
                          right: '268px'
                        }}
                      >
                        {
                          (headroomState === true) ? <Logo_white title={title} /> : <Logo title={title} />
                        }
                      </Box>
                    </Link>
                  </Box>
                )}
                <Box 
                  mt={['40px', null, 0]}
                  ml={40}
                  fontSize={16}
                  width={73}
                  height={24}
                  sx={{
                    letterSpacing:'2.08px',
                    '@media screen and (max-width: 414px)': {
                      marginLeft: 0,
                      width: '94px',
                      fontSize: "21px",
                      fontWeight: "bold",
                      letterSpacing:'2.32px',
                      height: '30px'
                    }
                  }}
                >
                  <Link to={`/${page}/`}>
                    <LinkText color={color}>{name}</LinkText>
                  </Link>
                </Box>
              </React.Fragment>
            ))}
          </Flex>
          <Box
            display={['none', null, null, 'flex']}
            sx={{
              alignItems: 'center',
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
            }}
          >
            {headerSNS.map(({ name, url, icon: Icon }) => (
              <ExternalLink key={name} href={url} color={color} ml="24px">
                <Icon width="22px" />
              </ExternalLink>
            ))}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

const LinkText = styled(Text).attrs({
  fontFamily: ['serif', null, 'body'],
  fontSize: [21, null, 16],
  fontWeight: ['bold', null, 'normal'],
})``;
const Bar = styled(Box)`
  height: 3px;
  background-color: currentColor;
  transition: transform 000.3s ease-in-out;
  .active > & {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    margin-top: calc(3px / 2 * -1);
    &:first-child {
      transform: rotate(45deg);
    }
    &:nth-child(2) {
      transform: rotateY(90deg);
    }
    &:last-child {
      transform: rotate(-45deg);
    }
  }
`;
export default Header;
