import Facebook from '../assets/icons/facebook.svg';
import Youtube from '../assets/icons/youtube.svg';
import Instagram from '../assets/icons/instagram.svg';
import Line from '../assets/icons/line.svg';

type PageName = 'about' | 'contact' | 'beautyshare' | 'map' | 'news' | 'video';
type NavType = {
  page: PageName;
  name: string;
}[];
type SNSType = {
  // name: 'youtube' | 'instagram' | 'facebook' | 'line';
  // name: 'instagram' | 'facebook' | 'line';
  name: 'instagram' | 'line';
  url: string;
  icon: typeof Instagram;
}[];

export const GATSBY_URL = 'https://www.gatsbyjs.org/';
export const WORDPRESS_URL = 'https://www.wordpress.com/';
export const NAV: NavType = [
  //{ page: 'beautyshare', name: '美麗分享' },
  //{ page: 'faq', name: '美麗分享' },
  { page: 'news', name: '媒體報導' },
  //{ page: 'about', name: '關於魔方' },
  { page: 'video', name: '影音分享' },
  { page: 'map', name: '診所地圖' },
  { page: 'contact', name: '預約諮詢' },
];
export const NAV_FOOTER: NavType = [
  //{ page: 'about', name: '關於魔方' },
  //{ page: 'beautyshare', name: '美麗分享' },
  //{ page: 'faq', name: '美麗分享' },
  { page: 'news', name: '媒體報導' },
  { page: 'video', name: '影音分享' },
  { page: 'map', name: '診所地圖' },
  { page: 'contact', name: '預約諮詢' },
];
export const SNS: SNSType = [
 // {
 //   name: 'youtube',
 //   url: 'https://www.youtube.com/channel/UCIwStXd_HkpeDUubbDKA53A',
 //   icon: Youtube,
 // },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/profoundlifting/',
    icon: Instagram,
  },
//  {
//    name: 'facebook',
//    url: 'https://www.facebook.com/Profound67',
//    icon: Facebook,
//  },
  { name: 'line', url: 'https://lin.ee/xh24Ceq', icon: Line },
];
