import preset, { Theme } from '@rebass/preset';
import 'styled-components';

declare module 'styled-components' {
  // eslint-disable-next-line
  export interface DefaultTheme extends Theme {}
}

const BASE_THEME = preset as Theme;

const mobileHeaderPy = {
  pt: 32,
  pb: 16,
};
export const gridPadding = 40;
const coverMenuToggle = 28;
const mobileHeaderHeight =
  coverMenuToggle + mobileHeaderPy.pt + mobileHeaderPy.pb;
const container = '992px';
const containerWide = '1240px';

export const breakpoints = ['52em', '64em', containerWide];

const theme: Theme = {
  ...BASE_THEME,
  breakpoints,
  sizes: {
    mobileHeaderHeight,
    coverMenuToggle,
    containerWide,
    container,
  },
  space: {
    gridPadding,
  },
  colors: {
    background: {
      white: '#fffff9',
      light: '#ecd3e5',
      gray: '#414141',
      purple: '#cf92bf',
    },
    white: '#ffffff',
    black: '#000000',
    dark: '#414141',
    gray: '#616161',
    red: '#ea1d0e',
    purple: '#9f247f',
    primary: '#bc66a5',
    secondary: '#cf92bf',
  },
  fonts: {
    body:
      '"Noto Sans TC", -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,"Helvetica Neue",Arial,sans-serif',
    heading: 'inherit',
    monospace: 'monospace',
    serif: '"Noto Serif TC", serif',
  },
  zIndices: {
    asideStickyButton: 900,
    coverMenu: 1000,
    coverMenuToggle: 1001,
    modal: 1100,
  },
  variants: {
    mobileHeader: {
      ...mobileHeaderPy,
      px: gridPadding,
      bg: 'background.white',
    },
    container: {
      width: '100%',
      maxWidth: 'container',
      px: [gridPadding, null, 0],
      mx: 'auto',
    },
    wideContainer: {
      width: '100%',
      maxWidth: 'containerWide',
      px: [gridPadding, null, null, 0],
      mx: 'auto',
    },
    fadeChange: {
      transitionProperty: 'opacity, visibility',
      transitionDuration: '.3s',
      transitionDelay: '0s, .3s',
    },
    deviceIntro: {
      maxWidth: [null, 275],
      position: [null, 'absolute'],
    },
    deviceLine: {
      display: ['none', null, 'inline'],
      position: [null, null, 'absolute'],
    },
  },
  text: {
    accordion: {
      subtitle: {
        as: 'h4',
        // fontSize: "24px" , 
        // lineHeight: "36px" ,
        // letterSpacing: "2px" ,
        // fontWeight: 400 , /* regular */
        // fontFamily: 'Noto Sans TC' ,
      },
      text: {
        fontSize: "16px" , 
        lineHeight: "24px" ,
        letterSpacing: "1.5px" ,
        fontWeight: 400 , /* regular */
        fontFamily: 'Noto Sans TC' ,
        '-webkitFontSmoothing': 'antialiased',
        '-mozOsxFontSmoothing': 'antialiased',
      },
    },
    heading: {
      gradient: {
        py: '6px',
        px: gridPadding,
        my: ['2em'],
        fontSize: [24, 36],
        fontFamily: 'serif',
        backgroundImage: 'linear-gradient(to left, #ffffff, #cf92bf)',
        backgroundSize: '50% 100%',
        backgroundRepeat: 'no-repeat',
        [`@media screen and (min-width: ${container})`]: {
          px: `calc((100vw - ${container}) / 2)`,
        },
        [`@media screen and (min-width: ${containerWide})`]: {
          px: `calc((100vw - ${containerWide}) / 2)`,
        },
      },
    },
    table: {
      thead: {
        display: 'inline-block',
        fontSize: [12, 21],
        pb: '4px',
        mb: ['4px', '12px'],
        borderWidth: '0 0 1px 0',
        borderStyle: 'solid',
        borderColor: 'dark',
      },
      th: {
        pr: [null, '16px'],
        minWidth: '5em',
        fontSize: [12, 18],
        fontWeight: 'normal',
        verticalAlign: 'middle',
        textAlign: 'right',
      },
      td: {
        p: ['8px 16px', null, '16px'],
        color: 'white',
        fontSize: [14, 16],
        textAlign: 'center',
        fontWeight: 500,
      },
    },
  },
  forms: {
    label: {
      fontSize: ['12px', '14px'],
      mb: '8px',
    },
    input: {
      border: 'none',
      borderBottom: '1px solid',
      borderRadius: 0,
      height: 40,
    },
    select: {
      border: 'none',
      borderBottom: '1px solid',
      borderRadius: 0,
      height: 40,
    },
    textarea: {
      borderRadius: 0,
    },
  },
  buttons: {
    outline: {
      p: `${16 / 14}em ${30 / 14}em`,
      fontWeight: 'normal',
      fontFamily: 'serif',
      fontSize: '14px',
      border: '1px solid #000',
      borderRadius: 0,
      bg: 'white',
      color: 'black',
      cursor: 'pointer',
      lineHeight: 1,
      '&:hover': {
        bg: 'background.light',
      },
    },
    heroOutline: {
      p: '1em 1.875em',
      fontWeight: 'normal',
      fontFamily: 'serif',
      fontSize: [16, 18],
      border: '1px solid currentColor',
      color: 'white',
      borderRadius: 0,
      bg: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        bg: 'background.light',
        color: 'dark',
        borderColor: 'background.light',
      },
    },
    carouselController: {
      size: 47,
      borderRadius: 0,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'currentColor',
      color: '#d2c4e5',
      textAlign: 'center',
      bg: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        color: 'white',
        bg: 'secondary',
        borderColor: 'secondary',
      },
    },
  },
};

export default theme;
