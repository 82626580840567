import React, { ReactNode } from 'react';
import { Normalize } from 'styled-normalize';
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro';
import { Box } from 'rebass/styled-components';
import theme from '../theme';
import Header from './Header';
import Footer from './Footer';
import { AsideStickyButton } from './AsideStickyButton';
// import 'tippy.js/dist/tippy.css'; // eslint-disable-line

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  body {
    box-sizing: border-box;
    font-family: ${(props) => props.theme.fonts.body};
    background: ${(props) => props.theme.colors.background.white};
    color: ${(props) => props.theme.colors.black};
    line-height: normal;
  }

  img {
    vertical-align: middle;
  }
`;

type Props = {
  headroom?: boolean;
  headroomState?: boolean;
  headroomMobileState?: boolean;
  children: ReactNode;
};

const Layout = ({
  headroom = false,
  headroomState = false,
  headroomMobileState = false,
  children,
}: Props) => (
  <main>
    <ThemeProvider theme={theme}>
      <Normalize />
      <GlobalStyle />
      <Box
        pt={
          headroom
            ? null
            : [theme.sizes.mobileHeaderHeight, null, null, '104px']
        }
      >
        <Header
          headroomState={headroomState}
          headroomMobileState={headroomMobileState}
        />
        {children}
        {<Footer />}
        <AsideStickyButton />
      </Box>
    </ThemeProvider>
  </main>
);

export default Layout;
