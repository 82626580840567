import { graphql, useStaticQuery } from 'gatsby';

type siteMetadata = {
  title: string;
  description: string;
  deterministic: string;
};

type QueryResponse = {
  site: {
    siteMetadata: siteMetadata;
  };
};

export const useSiteQuery = (): siteMetadata => {
  const { site } = useStaticQuery<QueryResponse>(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
          deterministic
        }
      }
    }
  `);

  return { ...site.siteMetadata };
};
