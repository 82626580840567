import React from 'react';
import Link from 'gatsby-link';
import { Text } from 'rebass/styled-components';

export const AsideStickyButton = () => (
  <Link to="/contact">
    <Text
      py={32}
      width="70px"
      color="white"
      backgroundColor="background.gray"
      textAlign="center"
      lineHeight={1.5}
      fontFamily="serif"
      sx={{
        display: ['none', null, 'inline-block'],
        wordBreak: 'break-all',
        position: 'fixed',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 'asideStickyButton',
      }}
    >
      預<br />約<br />諮<br />詢<br />
    </Text>
  </Link>
);
