import React from 'react';
import { Text, Box, Flex } from 'rebass/styled-components';
import ExternalLink from './ExternalLink';
import Link from './Link';
import Logo from '../assets/icons/white.svg';
import { useSiteQuery } from '../queries/useSiteQuery';
import { NAV_FOOTER, SNS } from '../utils/constants';

const Footer = () => {
  const { title } = useSiteQuery();

  return (
    <Box bg="background.gray">
      <Flex
        height="232px"
        flexDirection="column"
        maxWidth="containerWide"
        mx="auto"
        p={['40px', null, '50px 40px', '50px 0']}
        sx={{
          position: 'relative',
        }}
      >
        <Flex pt={[3, 0]} width={[1 / 2, 'auto']} flexWrap={['wrap', 'nowrap']} alignItems="center">
          {NAV_FOOTER.map(({ page, name }) => (
            <Flex key={page} width={[1 / 2, 'auto']} mr={[null, 56]} alignItems="center" sx={{
              '@media screen and (max-width: 414px)': {
                marginBottom: "24px",
              }
            }}>
              <Link to={`/${page}/`}>
                <Text color="white" fontSize={[14, null, 18]} fontWeight="normal">
                  {name}
                </Text>
              </Link>
            </Flex>
          ))}

        </Flex>
        <Flex ml="auto" mr={17} mt="-24px" alignItems="center" sx={{
          '@media screen and (max-width: 414px)': {
            marginTop: 0,
            marginRight: 0,
          }
        }}>
            {SNS.map(({ name, url, icon: Icon }) => (
              <ExternalLink color="white" key={name} href={url} ml="17px" width={[1 / 2, 'auto']}  >
                <Icon width="24px" height="24px"/>
              </ExternalLink>
            ))}
          </Flex>

        <Flex
          flexDirection={['column', 'row']}
          alignItems={['flex-end', 'center']}
          justifyContent="space-between"
          mt="56px"
          //fontWeight="normal"
          sx={{
            letterSpacing: "1.55px",
            fontWeight: "normal",
            '@media screen and (max-width: 414px)': {
              marginTop: 17,
            }
          }}
        >
          <Text color="white" fontSize={[12, null, 14]} fontWeight="normal">
            © 2022 SYNERON CANDELA 版權所有<br/>
                禁止任何網際網路服務業者，轉錄本網站資訊內容供人點閱
          </Text>
          <Box
            width="169px"
            height="21px"
            ml="auto"
            mr={17}
            color="white"
            sx={{
              position: ['absolute', 'static'],
              top: '50px',
              right: '40px',
              '@media screen and (max-width: 414px)': {
                width: "35%",
                marginRight: 0,
                marginTop: "-4px"
              }
            }}
          >
            <Logo title={title} width="100%" />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
